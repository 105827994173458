<template>
  <div v-loading="loading">
    <fm-title :title-text="'药方构成 ' + (detailData ? detailData.pname : '')"></fm-title>
    <template v-if="detailData">
      <div class="detail-content">
        <div class="note">
          <div>功效</div>
          <p>{{detailData.effect}}</p>
        </div>
        <div class="note">
          <div>症状</div>
          <p>{{detailData.treatment}}</p>
        </div>
        <div class="tmp-item" :class="{'tmp-item-a': chooseData && chooseData.id === item.id}" @click="chooseItem(item)" v-for="item in medicineData.filter(v => !v.isDel)" :key="item.id">
          <div class="tmp-text">
            {{item.medicineName}}
            <i class="iconfont icon-point" v-if="!item.medicineTypeId"></i>
          </div>
          <div class="btns">
            <a href="javascript:;" @click="edit(item)">修改</a> |
            <a href="javascript:;" @click="del(item)">删除</a>
          </div>
        </div>
      </div>
    </template>
    <div class="detail-content c-note" v-else-if="!tmpData">
      请选择处方
    </div>
  </div>
</template>

<script>
import {
  prescriptionTmpRequest
} from '@/api'

export default {
  props: {
    tmpData: {
      type: Object
    },
    medicineList: {
      type: Array
    }
  },
  watch: {
    tmpData: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      loading: false,
      detailData: null,
      medicineData: [],
      chooseData: null,
      updateList: []
    }
  },
  methods: {
    chooseItem (item) {
      this.chooseData = item
      this.$emit('clearSearch')
    },
    async save () {
      let pList = this.updateList.map(v => {
        if (v.isDel) {
          prescriptionTmpRequest.delDetail(v.id)
        } else {
          prescriptionTmpRequest.updateDetail(v.id, v)
        }
      })
      if (pList.length > 0) {
        await Promise.all(pList)
        this.loadData()
        this.$emit('update')
      }
    },
    match (data) {
      if (this.chooseData === null) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择处方药品'
        })
        return
      }
      this.chooseData.medicineName = data.medicineTypeName
      this.chooseData.medicineTypeId = data.medicineTypeId
      this.updateList.push(this.chooseData)
      this.$emit('changed', true)
    },
    async edit (item) {
      const result = await this.$dialog.confirm({title: '药品名称', promptValue: item.medicineName ,showInput: true})
      if (result && item.medicineName !== result) {
        item.medicineName = result
        item.medicineTypeId = (this.medicineList.find(v => v.medicineTypeName === item.medicineName) || {medicineTypeId: null}).medicineTypeId
        this.updateList.push(item)
        this.$emit('changed', true)
      }
    },
    async del(item) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该药品吗?'})
      if (result) {
        item.isDel = 1
        this.updateList.push(item)
        this.$emit('changed', true)
      }
    },
    init () {
      this.medicineData = []
      this.updateList = []
      this.detailData = null
      this.$emit('changed', false)
    },
    async loadData () {
      this.init()
      if (this.tmpData != null) {
        this.loading = true
        let data = await prescriptionTmpRequest.get({
          pageNum: 1,
          pageSize: 10,
          id: this.tmpData.id
        })
        if (data && data.data && data.data.length > 0) {
          this.detailData = data.data[0]
          this.medicineData = this.detailData.medicineData
        }
        this.loading = false
      }
    }

  }
}
</script>

<style scoped lang="less">
.c-note {
    display: flex;
    justify-content: center;
    align-items: center;
  }
.note {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  background: #fafafa;
  p {
    font-size: 12px;
  }
}
.detail-content {
  height: calc(100% - 48px);
  overflow-y: auto;
  .tmp-item {
    margin: 5px 5px;
    padding: 5px 10px;
    background: #fafafa;
    border-radius: 3px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btns {
      min-width: 80px;
      a {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .icon-point {
    color: #ff9900;
    line-height: 1;
  }
  .tmp-item-a {
    background: #2E6BE5;
    color: #fff;
    .btns {
      a {
        color: #fff;
      }
    }
  }
}
</style>
