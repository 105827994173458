<template>
  <SimplePageNorm title="药方校验">
    <CheckContent style="width:100%;height:100%;"></CheckContent>
  </SimplePageNorm>
</template>

<script>
import SimplePageNorm from '@/components/base/SimplePageNorm'
import CheckContent from './check/content'

export default {
  components: {
    SimplePageNorm,
    CheckContent
  }
}
</script>

<style scoped lang="less">
.search-block {
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
}
</style>