<template>
  <div v-loading="loading" class="check-content">
    <div class="cf-list">
      <List @chooseTmp="chooseTmp" :chooseData="chooseData" style="width:100%;height:100%;" :data-list="dataList"></List>
    </div>
    <div class="detail content-item">
      <Detail @update="loadData" @clearSearch="$refs.medicineList.sText = ''" :medicineList="medicineList" @changed="(d) => detailChange = d" ref="detail" :tmpData="chooseData" style="width:100%;height:100%;"></Detail>
    </div>
    <div class="m-list content-item">
      <MedicineList @match="match" @changed="(d) => detailChange = d" ref="medicineList" @medicineListChange="medicineListChange" :tmpData="chooseData" style="width:100%;height:100%;"></MedicineList>
    </div>
    <div class="btn">
      <fm-btn v-if="chooseData && (detailChange || mChange)" style="margin: 0 30px" @click="save">保存</fm-btn>
      <fm-btn v-if="chooseData && (detailChange || mChange)" style="margin: 0 30px" @click="back">恢复</fm-btn>
      <fm-btn v-if="chooseData && !detailChange && !mChange" style="margin: 0 30px" @click="chooseTmp">下一个</fm-btn>
    </div>
  </div>
</template>

<script>

import {
  prescriptionTmpRequest
} from '@/api'

import List from './list'
import Detail from './detail'
import MedicineList from './medicineList'

export default {
  components: {
    List,
    Detail,
    MedicineList
  },
  created() {
    this.loadData()
  },
  data () {
    return {
      dataList: [],
      chooseData: null,
      medicineList: [],
      detailChange: false,
      mChange: false
    }
  },
  methods: {
    save () {
      this.$refs.detail.save()
      this.$refs.medicineList.save()
    },
    back () {
      this.$refs.detail.loadData()
      this.$refs.medicineList.loadData()
    },
    match (data) {
      this.$refs.detail.match(data)
    },
    medicineListChange (data) {
      this.medicineList = data
    },
    chooseTmp (data) {
      if (this.detailChange || this.mChange) {
        this.$notice.info({
          title: '系统提示',
          desc: '保存后再切换其他处方。'
        })
        return
      }
      if (data) {
        this.chooseData = data
      } else if (this.chooseData) {
        let index = this.dataList.findIndex(v => v.id === this.chooseData.id)
        index += 1
        index = index === this.dataList.length ? 0 : index
        this.chooseData = this.dataList[index]
      }
    },
    async loadData () {
      this.loading = true
      this.dataList = await prescriptionTmpRequest.getToCheck({})
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.check-content {
  display: flex;
  position: relative;
  .btn {
    position: absolute;
    height: 40px;
    right: 0;
    bottom: 0;
    left: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-item {
    flex: 1;
    height: calc(100% - 40px);
  }
  .cf-list {
    width: 300px;
  }
}
</style>