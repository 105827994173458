<template>
  <div>
    <fm-title title-text="药方列表" :note-text="note"></fm-title>
    <div class="search-c">
      <fm-input-new v-model="sText"></fm-input-new>
      <fm-switch size="mini" v-model="isError" style="margin-right: 10px;">
        <span slot="off">全部</span>
        <span slot="on">异常</span>
      </fm-switch>
    </div>
    <div class="tmp-list">
      <div class="tmp-item" :class="{'tmp-item-a': chooseData && chooseData.id === item.id}" @click="chooseItem(item)" v-for="item in showList" :key="item.id">
        <div class="tmp-text">
          {{item.pname}}
        </div>
        <i class="iconfont icon-point" v-if="item.cmNull"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array
    },
    chooseData: {
      type: Object
    }
  },
  data () {
    return {
      sText: '',
      isError: false
    }
  },
  computed: {
    showList () {
      return this.dataList.filter(v => (!this.sText || v.pname.includes(this.sText)) && (!this.isError || v.cmNull))
    },
    note () {
      return this.showList.filter(v => !v.cmNull).length + '/' + this.showList.length
    }
  },
  methods: {
    chooseItem (item) {
      this.$emit('chooseTmp', item)
    }
  }
}
</script>

<style scoped lang="less">
.tmp-list {
  height: calc(100% - 48px - 28px - 15px);
  overflow-y: auto;
  .tmp-item {
    margin: 5px 5px;
    padding: 8px 10px;
    background: #fafafa;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-point {
    color: #ff9900;
    line-height: 1;
  }
  .tmp-item-a {
    background: #2E6BE5;
    color: #fff;
  }
}
.search-c {
    width: calc(100% - 10px);
    margin: 0 5px;margin-bottom:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
