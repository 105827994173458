<template>
  <div v-loading="loading">
    <fm-title title-text="三九药品列表"></fm-title>
    <fm-input-new v-model="sText" style="width: calc(100% - 10px);margin: 0 5px;margin-bottom:5px;"></fm-input-new>
      <div class="detail-content">
        <div class="tmp-item">
          <div class="tmp-text">
            三九名称
          </div>
          <div class="tmp-text">
            药品种类
          </div>
          <div class="btns">
          </div>
        </div>
        <div class="tmp-item" :class="{'tmp-item-a': chooseData && chooseData.id === item.id}" @click="chooseData = item" v-for="item in showList" :key="item.id">
          <div class="tmp-text">
            {{item.name}}
          </div>
          <div class="tmp-text">
            {{item.medicineTypeName}}
          </div>
          <div class="btns">
            <a href="javascript:;" @click="edit(item)">修改</a> |
            <a href="javascript:;" @click="match(item)">关联</a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {
  medicineRequest,
  medicineTypeRequest
} from '@/api'

export default {
  data () {
    return {
      sText: '',
      loading: false,
      medicineList: [],
      chooseData: null,
      updateList: []
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    showList () {
      return this.medicineList.filter(v => !this.sText || v.name.includes(this.sText))
    }
  },
  methods: {
    async save () {
      let pList = this.updateList.map(v => {
        medicineTypeRequest.update(v.medicineTypeId, {
          name: v.medicineTypeName
        })
      })
      if (pList.length > 0) {
        await Promise.all(pList)
        this.loadData()
      }
    },
    async edit (item) {
      const result = await this.$dialog.confirm({title: '药品种类', promptValue: item.medicineTypeName, showInput: true})
      if (result && item.medicineTypeName !== result) {
        item.medicineTypeName = result
        this.updateList.push(item)
        this.$emit('changed', true)
      }
    },
    async match(item) {
      this.$emit('match', item)
    },
    init () {
      this.medicineList = []
      this.updateList = []
      this.chooseData = null
      this.$emit('changed', false)
    },
    async loadData () {
      this.init()
      this.loading = true
      let data = await medicineRequest.get({
        manufacturer: '华润三九'
      })
      let map = {}
      let newData = []
      data.forEach(v => {
        let key = v.name + '-' + v.medicineTypeName + '-' + v.medicineTypeId
        if (!map[key] && v.medicineTypeId) {
          map[key] = 1
          newData.push(v)
        }
      })
      this.medicineList = newData
      this.$emit('medicineListChange', newData)
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.c-note {
    display: flex;
    justify-content: center;
    align-items: center;
  }
.detail-content {
  height: calc(100% - 48px - 28px - 10px);
  overflow-y: auto;
  .tmp-item {
    margin: 5px 5px;
    padding: 5px 10px;
    background: #fafafa;
    border-radius: 3px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btns {
      width: 80px;
      a {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .tmp-text {
    felx: 1;
  }
  .icon-point {
    color: #ff9900;
  }
  .tmp-item-a {
    background: #2E6BE5;
    color: #fff;
    .btns {
      a {
        color: #fff;
      }
    }
  }
}
</style>
